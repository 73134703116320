#isotope-section {
    overflow: hidden;

    @media screen and (min-width: 992px) {
        min-height: 500px;
    }
}

.pipe {
    position: absolute;
    height: 100%;
    height: calc(100% + 1.9rem);
    top: -.95rem;
    width: .95rem;
    border: 2px white solid;
}

.isotope-box::before {
    @extend .pipe;
    content: "";
    border-right: none;
    left: -.95rem;
    z-index: 100;
    background-color: #1d304e;

    &.hide-clamps-on-mobile {
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
}

.isotope-box::after {
    @extend .pipe;
    content: "";
    border-left: none;
    // right:0;
    right: -.95rem;
}

.hide-clamps-on-mobile::before, .hide-clamps-on-mobile::after {
    @media screen and (max-width: 600px) {
        display: none;
    }
}

.isotope-box {
    position: relative;
    background-color: #1d304e;
    z-index: 5;
    transition: height 500ms;
    padding: 0 !important;
}

@media screen and (max-width: 992px) and (min-width: 601px) {

    .isotope-header {
        z-index: 99 !important;
        background: #1d304e;
    }

    .isotope-header.pushpin-medium.pinned::after {
        content: "";
        position: absolute;
        height: 10px;
        width: 120%;
        bottom: 0;
        left: -10%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
    }

}

@media screen and (min-width: 1100px) {
    .isotope-box {
        top: 3px;
    }
}

.box {
    padding: 0 !important;
    margin: 0.95rem 0;
    transition: transform 200ms;

    .box-icon {
        @include aspect-ratio(1, 1);

        img {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 55%;
            max-width: 65%;
        }
    }

    .box-title {
        color: $light-blue;
        line-height: 1.4rem;
        font-size: 1.35rem;
    }

    .box-desc {
        color: #000;
        line-height: 1.2rem;
        font-size: 0.9rem;
    }
}

.box-min {
    transition: transform 200ms;
    .box-title {
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 993px) {

    .box-min:hover, .box:hover {
        transform: scale(1.05);
        transition: transform 200ms;
    }
}

.no-padding {
    padding: 0;
}

// box for animate horizontally pipe

.show-isotope-menu {
    a.btn-big {
        position: relative;
        height: 40px;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: -1px;
        }
    }
}

.btn.btn-big.show-isotope-menu {
    // position: absolute;
    // top: 1rem;
    // right: 3rem;
    // max-height: 100px;
    // margin-top: 1.75rem;
    overflow: hidden;

    // transition: 350ms ease-out;
}

i.isotope-back.left {
    font-size: 2.5rem;
    margin-right: 0;
}

.col.all-services-col {
    padding: 0 !important;
}

.col.show-isotope-menu {

    &:first-child {
        padding-left: 0;
    }

    transition: 350ms ease-out;
}

.btn.btn-big.show-isotope-menu.fade, .col.s4.show-isotope-menu.fade {
    opacity: 0;
    width: 0;
    padding: 0;
    border: none;
    transition: 350ms ease-out;
}

@media screen and (max-width: 499px) {

    hr.no-margin {
        margin-bottom: 0;
    }

    .btn.btn-big.show-isotope-menu {
        position: relative;
        top: 0;
        right: 0;
        margin-bottom: 1rem;
    }
}

.btn-box {
    position: absolute;
    width: 300px;
    z-index: 1;
}

.btn-box.row {
    margin-left: -1rem;
}

.btn.btn-big {
    font-size: 1.35rem;
    background-color: transparent;
    margin-top: 1.75rem;
    border: 2px #fff solid;
    width: 225px;
    text-transform: none;
    height: auto;
    overflow: visible;
    position: relative;
    z-index: auto;
    background-color: #1d304e;
    transition: 350ms ease-out;

    &.small-margin {
        margin-top: .75rem;
    }
}

.btn.btn-big:hover {
    background-color: #0eb0ee;
}

.btn.btn-big.active {
    background-color: #fff;
    color: #0eb0ee;
    overflow: visible;
}

.btn.btn-big.highlight {
    color: #fff;
    background-color: $orange;
    border-color: $orange;

    &.disabled {
        border-color: transparent;
    }
}

.submenu-extra-text {
    font-weight: normal;
    font-size: 1.2rem;
    max-width: 245px;
    padding: 0 5px;
}

// .show-isotope-menu{
//     display: none;
// }

.btn.btn-big.show-isotope-menu.active {
    background-color: #1d304e;
    // color: #fff;
}

.btn-box.isotope-mobile-show-menu {
    width: 100%;
    display: inline-flex;
    padding-top: 1.75rem;
    margin-bottom: 0;
}

@media screen and (min-width: 993px) {

    .btn.btn-big::after {
        content: "";
        width: 100px; // width: 0;
        height: 3px;
        background-color: #fff;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        transition: all 750ms;
    }

    .btn.btn-big.active::after {
        // width: 130px;
        right: -100px;
        transition: all 750ms;
    }

}

.btn-align-bottom {
    position: absolute;
    bottom: 0;
}

.s-isotope-filter {
    display: none;
}

@media screen and (max-width: 755px) {
    .s-isotope-filter {
        display: block;
    }
    .l-isotope-filter {
        display: none;
    }
}

// .tenants, .investors, .owners{
//     height: 0;
//     overflow: hidden;
// }

// TODO: add transition to all changed elements

.box-min .box-title, .box .box-title, .btn.btn-big {
    transition: 450ms;
}

.media-break-isotope {
    display: none;
}

@media screen and (min-width: 993px) and (max-width: 1124px) {
    .box.box-min .box-title {
        font-size: .8rem;
    }
    .box .box-title {
        line-height: 1.1rem;
        font-size: 1rem;
    }
    .box .box-desc {
        line-height: 1.1rem;
        font-size: .8rem;
    }
    .media-break-isotope {
        display: inline;
    }
}

@media screen and (min-width: 1125px) and (max-width: 1199px) {
    .box-min .box-title {
        font-size: .9rem;
    }
    .media-break-isotope {
        display: inline;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
    .box-min .box-title {
        font-size: 1rem;
    }
    .media-break-isotope {
        display: inline;
    }
}

@media screen and (max-width: 1099px) {
    .btn.btn-big {
        font-size: 1.15rem;
        width: 200px;
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 601px - 900px ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

@media screen and (max-width: 992px) {
    .btn-box {
        position: relative;
        width: auto;
        // margin-bottom: 3rem;

        .btn.btn-big {
            width: 100%;
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }
    .isotope-box {
        margin-top: 1.55rem;
        margin-left: 1rem !important;
        margin-right: 1rem;

        &.hide-clamps-on-mobile {
            @media screen and (max-width: 600px) {
                margin-left: 0 !important;
                margin-right: 0;
            }
        }
    }
}

@media screen and (min-width: 700px) and (max-width: 992px) {
    .box.box-min .box-title {
        font-size: .8rem;
        line-height: 1.1;
    }

    .box .box-title {
        font-size: .9rem;
        line-height: 1;
    }

    .box .box-desc {
        font-size: .8rem;
        line-height: 1;
    }
}

@media screen and (min-width: 800px) and (max-width: 992px) {
    .box.box-min .box-title {
        font-size: .9rem;
    }

    .box .box-title {
        font-size: 1.1rem;
    }

    .box .box-desc {
        font-size: .85rem;
        line-height: 1.2;
    }
}

@media screen and (min-width: 900px) and (max-width: 992px) {
    .box.box-min .box-title {
        font-size: 1rem;
        line-height: 1.2
    }

    .box .box-title {
        font-size: 1.25rem;
        line-height: 1.35;
    }

    .box .box-desc {
        font-size: .9rem;
        line-height: 1.2;
    }
}

@media screen and (min-width: 601px) and (max-width: 755px) {
    .box .box-desc {
        display: none;
    }
    .box .box-title {
        font-size: 1.1rem;
        line-height: 1.1;
    }
}

@media screen and (min-width: 993px) {
    #isotope-section {
        min-height: 720px;

        &.height-auto {
            height: auto;
        }
    }

    .isotope-header {
        position: relative;
        min-height: 500px !important;
    }
    .lflex {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ < 600px ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //
@media screen and (min-width: 500px) and (max-width: 600px) {
    .box .box-title {
        font-size: 1.12rem;
        line-height: 1.25;
    }
    .box .box-desc {
        display: block;
    }
    .isotope-mobile-menu {
        .box .box-title {
            font-size: 1.85rem;
        }
    }
}

@media screen and (min-width: 400px) and (max-width: 499px) {
    .box .box-title {
        font-size: 1rem;
        line-height: 1.15;
    }
    .box .box-desc {
        font-size: .8rem;
        line-height: 1.1;
    }
    .box .box-desc {
        display: block;
    }
    .isotope-mobile-menu {
        .box .box-title {
            font-size: 1.55rem;
        }
    }
}

@media screen and (max-width: 450px) {
    .box .box-desc {
        display: none;
    }
}

@media screen and (max-width: 399px) {
    .box .box-desc {
        display: none;
    }
    .box .box-title {
        font-size: 1rem;
        line-height: 1.25;
    }

    .isotope-mobile-menu {
        .box .box-title {
            font-size: 1.2rem !important;
        }
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ PL ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

.pl {

    .box .box-title {
        font-size: 1.12rem;
        margin: .75rem 0;

        @media screen and (max-width: 399px) {
            font-size: .95rem;
        }

        &.mobile-menu{
            font-size: 1.85rem;
            line-height: 1.25;
            font-weight: 700;
            margin: 1.46rem 0 1.168rem;
        }
    }

    .box-min {
        .box-title {
            font-size: .9rem;
            line-height: 1.35;
            margin: 1rem 0;
            display: block;
            font-weight: 700;
            min-height: 2.35rem;

            @media screen and (min-width: 1076px) and (max-width: 1250px) {
                font-size: .7rem;
                min-height: 1.75rem;
            }
            @media screen and (min-width: 993px) and (max-width: 1075px) {
                font-size: .7rem;
                min-height: 2.75rem;
            }
            @media screen and (min-width: 800px) and (max-width: 992px) {
                font-size: .8rem;
                min-height: 3rem;
            }
            @media screen and (min-width: 773px) and (max-width: 799px) {
                font-size: .69rem;
                min-height: 2.55rem;

            }
        }
    }

    .btn.btn-big {
        font-size: 1.15rem;

        @media screen and (max-width: 1099px) {
            font-size: 1rem;
        }
    }

}

// helpers
// .helper-for-pushpin-height{
// min-height: 500px !important;
// }

.pin-bottom.pushpin-medium::before, .pinned.pushpin-medium::before {
    content: "";
    display: block;
    height: 100px;
    width: 100%;
    position: absolute;
    background: $dark-blue;
    top: -100px;
}

.pushpin-medium.pin-bottom .col:nth-child(1), .pushpin-medium.pin-bottom .col:nth-child(2) {
    margin-top: -25px;
}