//Default styles

html {
 box-sizing: border-box;
}
*, *:before, *:after {
 box-sizing: inherit;
}

body {
  // display: flex;
  // min-height: 100vh;
  // flex-direction: column;
}

main {
  // flex: 1 0 auto;
}

ul {
  &:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;

    li {
      list-style-type: none;
    }
  }
}

a {
	color: $link-color;
	text-decoration: none;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;
}


// Positioning
.valign-wrapper {
  display: flex;
  align-items: center;

  .valign {
    display: block;
  }
}


// classic clearfix
.clearfix {
  clear: both;
}


// Z-levels
.z-depth-0 {
  box-shadow: none !important;
}
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}
.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}

.hoverable {
  transition: box-shadow .25s;
  box-shadow: 0;
}

.hoverable:hover {
  transition: box-shadow .25s;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// Dividers

.divider {
  height: 1px;
  overflow: hidden;
  background-color: color("grey", "lighten-2");
}


//  Blockquote

blockquote {
  margin: 20px 0;
  padding-left: 1.5rem;
  border-left: 5px solid $primary-color;
}

// Icon Styles

i {
  line-height: inherit;

  &.left {
    float: left;
    margin-right: 15px;
  }
  &.right {
    float: right;
    margin-left: 15px;
  }
  &.tiny {
    font-size: 1rem;
  }
  &.small {
    font-size: 2rem;
  }
  &.medium {
    font-size: 4rem;
  }
  &.large {
    font-size: 6rem;
  }
}

// Images
img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: auto;
}


// Pagination

.pagination {

  li {
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: 30px;

    a {
      color: #444;
      display: inline-block;
      font-size: 1.2rem;
      padding: 0 10px;
      line-height: 30px;
    }

    &.active a { color: #fff; }

    &.active { background-color: $primary-color; }

    &.disabled a {
      cursor: default;
      color: #999;
    }

    i {
      font-size: 2rem;
    }
  }


  li.pages ul li {
    display: inline-block;
    float: none;
  }
}
@media #{$medium-and-down} {
  .pagination {
    width: 100%;

    li.prev,
    li.next {
      width: 10%;
    }

    li.pages {
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

// Breadcrumbs
.breadcrumb {
  font-size: 18px;
  color: rgba(255,255,255, .7);

  i,
  [class^="mdi-"], [class*="mdi-"],
  i.material-icons {
    display: inline-block;
    float: left;
    font-size: 24px;
  }

  &:before {
    content: '\E5CC';
    color: rgba(255,255,255, .7);
    vertical-align: top;
    display: inline-block;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 25px;
    margin: 0 10px 0 8px;
    -webkit-font-smoothing: antialiased;
  }

  &:first-child:before {
    display: none;
  }

  &:last-child {
    color: #fff;
  }
}


// Parallax
.parallax-container {
  position: relative;
  overflow: hidden;
  height: 500px;
}

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  img {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
    transform: translateX(-50%);
  }
}

// Pushpin
.pin-top, .pin-bottom {
  position: relative;
}
.pinned {
  position: fixed !important;
}

/*********************
  Transition Classes
**********************/

ul.staggered-list li {
  opacity: 0;
}

.fade-in {
  opacity: 0;
  transform-origin: 0 50%;
}


/*********************
  Media Query Classes
**********************/
.hide-on-small-only, .hide-on-small-and-down {
  @media #{$small-and-down} {
    display: none !important;
  }
}
.hide-on-med-and-down {
  @media #{$medium-and-down} {
    display: none !important;
  }
}
.hide-on-med-and-up {
  @media #{$medium-and-up} {
    display: none !important;
  }
}
.hide-on-med-only {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: none !important;
  }
}
.hide-on-large-only {
  @media #{$large-and-up} {
    display: none !important;
  }
}
.show-on-large {
  @media #{$large-and-up} {
    display: block !important;
  }
}
.show-on-medium {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: block !important;
  }
}
.show-on-small {
  @media #{$small-and-down} {
    display: block !important;
  }
}
.show-on-medium-and-up {
  @media #{$medium-and-up} {
    display: block !important;
  }
}
.show-on-medium-and-down {
  @media #{$medium-and-down} {
    display: block !important;
  }
}


// Center text on mobile
.center-on-small-only {
  @media #{$small-and-down} {
    text-align: center;
  }
}

// Footer
.page-footer {
  padding-top: 20px;
  background-color: $footer-bg-color;

  .footer-copyright {
    overflow: hidden;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 10px 0px;
    color: rgba(255,255,255,.8);
    background-color: rgba(51,51,51,.08);
    @extend .light;
  }
}

// Tables
table, th, td {
   border: none;
}

table {
  width:100%;
  display: table;

  &.bordered > thead > tr,
  &.bordered > tbody > tr {
    border-bottom: 1px solid $table-border-color;
  }

  &.striped > tbody {
    > tr:nth-child(odd) {
      background-color: $table-striped-color;
    }

    > tr > td {
      border-radius: 0;
    }
  }

  &.highlight > tbody > tr {
    transition: background-color .25s ease;
    &:hover {
      background-color: $table-striped-color;
    }
  }

  &.centered {
    thead tr th, tbody tr td {
      text-align: center;
    }
  }

}

thead {
  border-bottom: 1px solid $table-border-color;
}

td, th{
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}

// Responsive Table
@media #{$medium-and-down} {

  table.responsive-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative;

    td:empty:before {
      content: '\00a0';
    }

    th,
    td {
      margin: 0;
      vertical-align: top;
    }

    th { text-align: left; }
    thead {
      display: block;
      float: left;

      tr {
        display: block;
        padding: 0 10px 0 0;

        th::before {
          content: "\00a0";
        }
      }
    }
    tbody {
      display: block;
      width: auto;
      position: relative;
      overflow-x: auto;
      white-space: nowrap;

      tr {
        display: inline-block;
        vertical-align: top;
      }
    }
    th {
      display: block;
      text-align: right;
    }
    td {
      display: block;
      min-height: 1.25em;
      text-align: left;
    }
    tr { padding: 0 10px; }

    /* sort out borders */
    thead {
      border: 0;
      border-right: 1px solid $table-border-color;
    }

    &.bordered {
      th { border-bottom: 0; border-left: 0; }
      td { border-left: 0; border-right: 0; border-bottom: 0; }
      tr { border: 0; }
      tbody tr { border-right: 1px solid $table-border-color; }
    }

  }

}


// Collections
.collection {
  margin: $element-top-margin 0 $element-bottom-margin 0;
  border: 1px solid $collection-border-color;
  border-radius: 2px;
  overflow: hidden;
  position: relative;

  .collection-item {
    background-color: $collection-bg-color;
    line-height: $collection-line-height;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid $collection-border-color;

    // Avatar Collection
    &.avatar {
      min-height: 84px;
      padding-left: 72px;
      position: relative;

      .circle {
        position: absolute;
        width: 42px;
        height: 42px;
        overflow: hidden;
        left: 15px;
        display: inline-block;
        vertical-align: middle;
      }
      i.circle {
        font-size: 18px;
        line-height: 42px;
        color: #fff;
        background-color: #999;
        text-align: center;
      }


      .title {
        font-size: 16px;
      }

      p {
        margin: 0;
      }

      .secondary-content {
        position: absolute;
        top: 16px;
        right: 16px;
      }

    }


    &:last-child {
      border-bottom: none;
    }

    &.active {
      background-color: $collection-active-bg-color;
      color: $collection-active-color;

      .secondary-content {
        color: #fff;
      }
    }
  }
  a.collection-item{
    display: block;
    transition: .25s;
    color: $collection-link-color;
    &:not(.active) {
      &:hover {
        background-color: $collection-hover-bg-color;
      }
    }
  }

  &.with-header {
    .collection-header {
      background-color: $collection-bg-color;
      border-bottom: 1px solid $collection-border-color;
      padding: 10px 20px;
    }
    .collection-item {
      padding-left: 30px;
    }
    .collection-item.avatar {
      padding-left: 72px;
    }
  }

}
// Made less specific to allow easier overriding
.secondary-content {
  float: right;
  color: $secondary-color;
}
.collapsible .collection {
  margin: 0;
  border: none;
}



// Responsive Videos
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}

// Progress Bar
.progress {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: lighten($progress-bar-color, 40%);
    border-radius: 2px;
    margin: $element-top-margin 0 $element-bottom-margin 0;
    overflow: hidden;
  .determinate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $progress-bar-color;
    transition: width .3s linear;
  }
  .indeterminate {
    background-color: $progress-bar-color;
    &:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate 2.1s cubic-bezier(0.650, 0.815, 0.735, 0.395) infinite;

    }
    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite;
      animation-delay: 1.15s;
    }
  }
}
@keyframes indeterminate {
    0% {
      left: -35%;
      right:100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
}


/*******************
  Utility Classes
*******************/

.hide {
  display: none !important;
}

// Text Align
.left-align {
  text-align: left;
}
.right-align {
  text-align: right
}
.center, .center-align {
  text-align: center;
}

.left {
  float: left !important;
}
.right {
  float: right !important;
}

// No Text Select
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.circle {
  border-radius: 50%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}
