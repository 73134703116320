.tap-target-wrapper {
  width: 800px;
  height: 800px;
  position: fixed;
  z-index: 1000;
  visibility: hidden;
  transition: visibility 0s .3s;
}

.tap-target-wrapper.open {
  visibility: visible;
  transition: visibility 0s;

  .tap-target {
    transform: scale(1);
    opacity: .95;
    transition:
      transform .3s cubic-bezier(.42,0,.58,1),
      opacity .3s cubic-bezier(.42,0,.58,1);
  }

  .tap-target-wave::before {
    transform: scale(1);
  }
  .tap-target-wave::after {
    visibility: visible;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    transition:
      opacity .3s,
      transform .3s,
      visibility 0s 1s;
  }
}


.tap-target {
  position: absolute;
  font-size: 1rem;
  border-radius: 50%;
  background-color: $primary-color;
  box-shadow: 0 20px 20px 0 rgba(0,0,0,0.14), 0 10px 50px 0 rgba(0,0,0,0.12), 0 30px 10px -20px rgba(0,0,0,0.2);
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(0);
  transition:
    transform .3s cubic-bezier(.42,0,.58,1),
    opacity .3s cubic-bezier(.42,0,.58,1);
}

@media only screen and (max-width: 600px) {
  .tap-target {
    width: 600px;
    height: 600px;
  }
}



.tap-target-content {
  position: relative;
  display: table-cell;
}

.tap-target-wave {
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
  }
  &::before {
    transform: scale(0);
    transition: transform .3s;
  }
  &::after {
    visibility: hidden;
    transition:
      opacity .3s,
      transform .3s,
      visibility 0s;
    z-index: -1;
  }

  position: absolute;
  border-radius: 50%;
  z-index: 10001;
}

.tap-target-origin {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  z-index: 10002;
  position: absolute !important;
}

.tap-target-origin:not(.btn), .tap-target-origin:not(.btn):hover {
  background: none;
}