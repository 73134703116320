.modal-overlay{
    background: #fff;
    z-index: 104 !important;
}
.overflow-hidden{
    overflow: hidden !important;
}

.modal-custom {
    background: $dark-blue;
    color: #fff;
    //overflow-y: scroll;
    overflow: hidden;
    height: 70%;
    max-height: 100%;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and(min-width: 993px) and (max-width: 1550px){
        width: 70%;
    }

    @media screen and (min-width: 601px) and (max-width: 1050px){
        width: 90%;
        height: 85%;
        max-height: 85%;
        top: 5%;
    }

    @media screen and (max-width: 600px){
        width: 100%;
        max-height: 100%;
        height: 100%;
        top: 0 !important;
    }
    .modal-title{
        margin-left: 3.55rem;
        margin-right: 3.55rem;
        margin-top: 2rem;
        position: relative;
        display: block;
        font-size: 2.28rem;
        font-family: Roboto,sans-serif;
        letter-spacing: .035rem;
        font-weight: 700;
        line-height: 1.12;

        @media screen and (max-width: 600px){
            font-size: 2rem;
            margin-left: 1.45rem;
        }

        @media screen and (max-width: 360px){
            font-size: 1.05rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        @media screen and (min-width: 601px) and (max-width: 992px){
            margin-left: 2.55rem;
            margin-right: 2.55rem;
        }

        span.bottom{
            position: absolute;
            bottom: 0;
        }
    }

    .modal-content{
        padding: 0;
        margin-top: 15px;
        position: absolute;
        // height: 100%;
        height: 75%;
        overflow-y: scroll !important;
        margin-right: 10px;
        max-width: 100%;
        //margin-bottom: 30px;

        .modal-section{
            padding: 0 calc(3.55rem - 17px) 2rem 3.55rem;
            padding-bottom: calc(2rem - 25px);
            overflow: auto;
            //max-height: 100%;
            //overflow: visible;


            &::before, &::after{
                content: "";
                display: block;
                position: fixed;
                width: 95%;
                height: 25px;
                left: 0;
                // background: #fff;
                z-index: 1;
            }

            &::before{
                background: -moz-linear-gradient(top, rgba(29,48,78,1) 0%, rgba(29,48,78,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(29,48,78,1) 0%,rgba(29,48,78,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(29,48,78,1) 0%,rgba(29,48,78,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }

            &::after{
                bottom: 23px;
                background: -moz-linear-gradient(top, rgba(29,48,78,0) 0%, rgba(29,48,78,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(29,48,78,0) 0%,rgba(29,48,78,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(29,48,78,0) 0%,rgba(29,48,78,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }

            &.hide-after::after{
                display: none;
            }

            @media screen and (min-width: 601px) and (max-width: 992px){
                padding: 0 2.25rem 2.25rem 2.25rem;
            }

            @media screen and (max-width: 600px){
                padding: 0 2rem 1.25rem 1.25rem;
            }

            .content img{
                height: 100%;
                max-width: 100%;
                margin: 0 !important;
            }

            article{
                padding: 0;
            }

            form, .row, .col{
                padding-left: 0;
            }

            form{
                .prefix{
                    &.top{
                        top: 0 !important;
                    }
                }
                [type=checkbox] + label:before, [type=checkbox]:not(.filled-in) + label:after{
                    left: 7px;
                }
                [type=checkbox]:checked + label{
                    color: #fff;
                    // display: block;
                    // height: auto;
                }
                // [type=checkbox]:checked, [type=checkbox]:not(:checked){
                //     top: 20px;
                //     left: 10px;
                // }
                .checkbox-container{
                    position: relative;
                }
            }

            &.modal-privacy-policy {
                position: fixed;
                // top: 10000px;
                background: $dark-blue;
                color: #fff;
                width: 100%;
                width: calc(100% - 10px);
                z-index: 1;

                // &.show{
                //     top: 0;
                //     position: absolute;
                //     animation: none;
                // }

                // &.hide{
                //     top: 1000px;
                //     position: fixed;
                //     animation: none;
                // }

            }
        }
    }

    a.modal-custom-close {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 999;

        color: #fff;
        border: 1px white solid;
        font-size: 22px;
        line-height: .85;
        padding: 4px;

        // @media screen and (max-width: 992px){
        //     top: 15px;
        //     right: 25px;
        // }
    }

    @media screen and (max-width: 800px){
        button.right{
            float: left !important;
            margin-left: 3.5rem;
        }
    }

    @media screen and (max-width: 450px){
        .modal-show-privacy-policy, button[type="submit"]{
            width: 80%;
        }
    }

    .magic-padding{
        padding-left: 3.5rem;
    }
    .magic-margin{
        margin-left: 3.5rem !important;
    }

}

.extra-margin-bottom{
    margin-bottom: 20px;
}

// .animate-up{
//     animation: goUp 900ms;
//     -webkit-animation-fill-mode: both;
//     animation-fill-mode: both;
// }

// .animate-down{
//     animation: goDown 900ms;
//     -webkit-animation-fill-mode: both;
//     animation-fill-mode: both;
// }
@media screen and (max-width: 1250px){
    .show-on-xlarge{
        display: none;
    }
}

@keyframes goUp {
    from {
        top: 1000px;
        position: fixed;
    }
    to {
        top: 0;
        position: absolute;
    }
}

@keyframes goDown {
    from {
        top: 0;
        position: absolute;
    }
    to {
        top: 1000px;
        position: fixed;
    }
}

.privacy-policy{
    counter-reset: a;

    li{
        font-weight: 500;
        margin: 1rem 1rem 1rem 2rem;
        position: relative;

        @media screen and (max-width: 600px){
            margin-right: 0;
        }

        &::before{
            counter-increment: a;
            content: "§ " counter(a) ". ";
            position: absolute;
            left: -2rem;
        }

        ol{
            counter-reset: b;
            margin: 0;

            li{
                padding-left: .25rem;
                font-weight: 300;
                margin: 1rem;

                @media screen and (max-width: 600px){
                    margin-right: 0;
                }

                &::before{
                    counter-increment: b;
                    content: counter(b) ". ";
                    left: -1rem;
                }

                ol{
                    list-style: lower-latin;

                    li{
                        padding-left: .25rem;
                        &::before{
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.rodo{
        list-style: decimal;

        li{
            font-weight: 300;

            &::before{
                content: none;
            }

        }
    }
}



/*
 *  STYLE 4
 */

.custom-scroll::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #1d304e;
    // border: 1px solid #fff;
}

.custom-scroll::-webkit-scrollbar {
    width: 7px;
    background-color: #fff;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #fff;
}


//* testy
.pseudo-parallax{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 244px;
    overflow: hidden;
    z-index: -1;

    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.18) 28%, rgba(0,0,0,0.65) 99%, rgba(0,0,0,0.65) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.18) 28%,rgba(0,0,0,0.65) 99%,rgba(0,0,0,0.65) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.18) 28%,rgba(0,0,0,0.65) 99%,rgba(0,0,0,0.65) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    }

    img{
        position: absolute;
        top: 0;
        min-height: 240px;
        min-width: 1440px;
        transform: translate(-10%, -10%);
    }
}

#modal-articles{
    height: 85%;

    @media screen and (max-width: 600px){
        height: 100%;
    }

    .modal-section{

        &::after{
            bottom: 0;
            height: 50px;

            background: -moz-linear-gradient(top, rgba(29,48,78,0) 0%, rgba(29,48,78,1) 50%, rgba(29,48,78,1) 100%);
            background: -webkit-linear-gradient(top, rgba(29,48,78,0) 0%,rgba(29,48,78,1) 50%,rgba(29,48,78,1) 100%);
            background: linear-gradient(to bottom, rgba(29,48,78,0) 0%,rgba(29,48,78,1) 50%,rgba(29,48,78,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001d304e', endColorstr='#1d304e',GradientType=0 );

        }

        .social{
            position: fixed;

            @media screen and ( max-width: 600px){
                position: static;
                margin-bottom: -1rem;
                a{
                    margin-left: 1rem;
                }
            }
        }

        .pushed{
            margin-left: calc(3.55rem + 16px);

            @media screen and (max-width: 600px){
                margin-left: 1rem;
            }
        }

        .content {
            font-size: 1.12em;
            line-height: 1.5;

            p{
                font-weight: 300;
                margin: .8em 0;

                &:first-child{
                    font-size: 1.1em;
                }
            }

            blockquote{
                border-color: $light-blue;
            }

            strong{
                font-weight: 500;
            }
        }

        .attachments{
            margin-top: 2em;
        }

        .newsletter{
            margin-top: 2rem;
            padding: .75rem 2rem;

            @media screen and (max-width: 600px){
                padding: .25rem 1rem .5rem;
            }

            .btn-center{
                margin-top: calc(20px - .4rem);
                margin-bottom: 20px;

                @media screen and (max-width: 800px){
                    margin-top: 0;
                    margin-right: .5rem;
                    float: right;
                }

                @media screen and (max-width: 600px){
                    float: initial;
                    margin: -.5rem auto 1rem 0;
                }
            }
            b{
                color: #9c1e15;
            }

            u.link{
                color: #fff;
                text-decoration: none;
                border-bottom: 3px $gold-highlight solid;
                font-weight: 500;
            }

            a:not(.btn){
                color: $gold-highlight;
                font-weight: 700;
            }
        }
        .policy{
            max-height: 0;
            overflow: hidden;
        }
    }

    .modal-title{

        .date{
            font-size: 1.1rem;
            position: absolute;
            bottom: 0;
            right: 17px;
        }
    }
}

@media screen and (min-width: 376px){
    .show-on-xs{
        display: none;
    }
}

@media screen and (max-width: 375px){
    .hide-on-xs{
        display: none;
    }
}

.min-thumbnail{
    height: 90px;
}

.extra-padding-top{
    padding-top: 1rem;
}

.download a{
    display: block;
}

@media screen and (max-width: 600px){
    .hide-on-start-mobile{
        display: none;
    }
}

.shadow-margin{
    margin-top: 1rem;
}

label.at-orange{
    color: #504f4f;

}

.list-for-checkbox{
    color: #9e9e9e;

    &.at-orange{
        color: #504f4f;
    }

    &.active{
        color: #fff;
    }
}

[type=checkbox]:required{
    left: 63px;
}

// .show-when-newsletter{
//     overflow: hidden;
//     max-height: 100; 
// }
