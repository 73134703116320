.infobox {
    display: flex;
    align-items: stretch;
    margin-top: 5px;

    .icon-box {
        @include aspect-ratio(1, 1);
        // width: 200px;
        // height: 200px;
        max-width: 140px;
        position: relative;
        background-color: $light-blue;
        overflow: hidden;
        margin-left: 0 !important;
        // border-right: 1px $light-blue solid;
        // border-top: 1px $light-blue solid;

        * {
            position: absolute;
        }

        .title {
            left: 1rem;
            top: .5rem;
            text-transform: uppercase;
            font-size: 1.25rem;
            color: #fff;
            // transform: rotate(270deg);

            @media screen and (min-width: 993px) {
                @media screen and (max-width: 1200px) {
                    font-size: 1.12rem;
                }
                @media screen and (max-width: 1080px) {
                    font-size: 1rem;
                }
            }

            @media screen and (max-width: 875px) {
                font-size: 1.12rem;
            }
            @media screen and (max-width: 800px) {
                font-size: 1rem;
                transform: rotate(180deg);
                writing-mode: vertical-rl;
                margin-left: 65%;
            }
            @media screen and (max-width: 375px) {
                font-size: 1.24rem;
                margin-left: 55%;
            }

            @media screen and (max-width: 350px) {
                font-size: 1.34rem;
                margin-left: 48%;
            }

        }

        .icon {
            display: block;
            left: 1rem;
            bottom: -1.2rem;
            font-family: Roboto, sans-serif;
            font-weight: 700;
            font-size: 4rem;
            background: #fff;
            color: $light-blue;
            opacity: .5;
            padding: 1.75rem 1.37rem;
            border-radius: 50%;
        }
    }

    .list-box {
        border: 4px $light-blue solid;
        margin-left: 0 !important;
        // width: calc(100% - 150px);
        .list {
            margin-top: .5rem;
            margin-bottom: 0;
            .row {
                margin-bottom: 0;
            }
            li {
                font-weight: 500;
            }
        }
    }
}

.infobox.highlight-box {
    .icon-box {
        background-color: $orange;

        span {
            color : $orange;
        }
        .icon {
            padding-left: 1.97rem;
            padding-right: 1.97rem;
        }
    }
    .list-box {
        border-color: $orange;

        ul.list li:before {
            color: $orange;
        }
    }
}

@media screen and (min-width: 601px) and (max-width: 699px) {
    .infobox .icon-box .icon {
        right: 1rem;
        left: auto !important;
        transition: 450ms ease-out;
    }
}

@media screen and (max-width: 500px) {
    .infobox .icon-box .icon {
        right: 1rem;
        left: auto !important;
        transition: 450ms ease-out;
    }
}
