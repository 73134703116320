.rent-box {
    clear: both;
    padding-top: .5rem;

    header {
        * {
            font-weight: 700;
            letter-spacing: .035rem;
            font-size: 2.5rem;
            margin: 1.46rem 0 1.168rem;
        }
    }
}

.rent-box-avatar, .rent-box-info {
    display: inline-block;
    float: left;
}

.rent-box-avatar {
    padding-right: 2rem;
}

.rent-box img {
    max-width: 100%;
    height: 150px;
    margin-top: 1.25rem;
}
