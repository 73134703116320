$submenu-size: 60px;

.square {
    height: $submenu-size;
    width: $submenu-size;
}

.submenu {
    background-color: transparent !important;
    height: auto;
    box-shadow: none;

    .submenu-list {
        padding-left: 2rem;
        overflow: hidden;
        margin-top: 2rem;
        margin-bottom: 1rem;

        .submenu-item {
            width: 100%;
            height: $submenu-size;
            margin-bottom: 1rem;
            margin-right: 2rem;

            .icon {
                padding: .75rem;
            }

            a{
                background-color: #fff;
                padding-left: 0;
                height: $submenu-size;
                width: 90%;
                width: calc(100% - 2rem);
                display: block;

                span{
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    transition: opacity 200ms;
                    letter-spacing: .2px;

                }

                span.title{
                    position: absolute;
                    color: $grey-text;
                    height: $submenu-size;
                    line-height: 1.1rem;
                    overflow: hidden;
                    font-size: 1rem;
                    padding: 1rem;
                    padding-top: .5rem;
                    opacity: 0.7;

                    &.upper{
                        text-transform: uppercase;
                    }

                    &.vcenter{
                        padding-top: .8rem;
                        padding-bottom: .75rem;
                    }
                }
                span.desc{
                    color: $light-blue;
                    font-weight: 400;
                    font-size: .8rem;
                    line-height: .9rem;
                }
                span.title.text-center{
                    padding-top: 1rem;
                }
            }

            &.highlight-item{
                a{
                    background: #ff7d01;
                }
                .title{
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1215px) {
    .submenu-hide{
        display: none;
    }
}

@media screen and (max-width: 1099px){
    .submenu .submenu-list{
        padding-left: 1rem;
        padding-right: 1rem;

        .submenu-item a{
            width: calc(100% - 1rem);
        }
    }
}

@media screen and (min-width: 601px) and (max-width: 992px){
    .submenu .submenu-list .submenu-item{
        margin-right: 0;
        width: 50%;
    }
}

@media screen and (min-width: 993px){

    .submenu .submenu-list{
        .submenu-item{
            a.active{
                width: 105%;
                transform: translate(2%, 1%);
                span{
                    opacity: 1;
                    transition: opacity 200ms;
                }
            }
        }

        .submenu-item a:hover{
            transform: translate(-1%, -1%);
            span.title{
                opacity: .85;
            }
        }
        .submenu-item a.active:hover{
            transform: translate(2%, 1%);
            span.title{
                opacity: 1;
            }
        }
    }
}


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ PL ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

@media screen and (min-width: 993px) and (max-width: 1215px){
    .pl .submenu .submenu-list .submenu-item a span.title{
        font-size: .9rem;
    }
}
