.info-box {
    position: relative;
    display: inline-block;
    //background: #fff;
    color: $text-color;
    height: 100%;
    padding: 0;
    width: 100%;
    //border-bottom: 4px solid $light-blue;
    z-index: 12;
    margin-bottom: -2px;
    overflow: hidden;

    .mini-title {
        margin-bottom: 1.55rem;

        span {
            display: block;
            margin-top: .35rem;
        }
    }

    &.header-extra-margin {
        @media all and(max-width: 600px) {
            margin-top: 1.55rem !important;
        }
    }
    // ~~~~~~~~~~~~~~~~~~~~ Only for info-box horizontal ~~~~~~~~~~~~~~~~~~~~ //
    @media all and (min-width: 601px) {
        &.horizontal {
            display: inline-block;
            // margin-bottom: -8px;
            .info-box-img {
                width: 290px;
                height: 290px;
                // border-bottom: 5px solid #0eb0ee;
                box-sizing: border-box;
                float: left;
                display: inline-block;
                //margin-bottom: -50px;
                img {
                    //object-fit: cover;
                    width: 100%;
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            .info-box-text {
                float: left;
                width: calc(100% - 290px);
                display: inline-block;
            }
        }
    }
    // ~~~~~~~~~~~~~~~~~~~~ Only for info-box vertical ~~~~~~~~~~~~~~~~~~~~ //
    @media all and (min-width: 601px) and (max-width: 992px) {
        &.vertical {
            display: inline-block;
            // margin-bottom: -8px;
            .info-box-img {
                width: 290px;
                height: 290px;
                // border-bottom: 5px solid #0eb0ee;
                box-sizing: border-box;
                float: left;
                display: inline-block;
                // margin-bottom: -50px;
                img {
                    //object-fit: cover;
                    width: 100%;
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            .info-box-text {
                float: left;
                width: calc(100% - 290px);
                display: inline-block;
            }
        }
    }

    // ~~~~~~~~~~~~~~~~~~~~ Only for info-box vertical for large ~~~~~~~~~~~~~~~~~~~~ //
    @media all and (min-width: 601px) {
        &.vertical.on-large {
            display: inline-block;
            // margin-bottom: -8px;
            .info-box-img {
                width: 290px;
                height: 290px;
                // border-bottom: 5px solid #0eb0ee;
                box-sizing: border-box;
                float: left;
                display: inline-block;
                // margin-bottom: -50px;
                img {
                    //object-fit: cover;
                    width: 100%;
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            .info-box-text {
                float: left;
                width: calc(100% - 290px);
                display: inline-block;
            }
        }
    }
    // ~~~~~~~~~~~~~~~~~~~~ unwersal ~~~~~~~~~~~~~~~~~~~~ //
    .info-box-img {
        position: relative;

        .preloader-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            z-index: -1;
        }

        img {
            width: 100%;
        }

    }

    p {
        margin-bottom: 0;

        &.line-limit {
            display: block;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (min-width: 993px) and (max-width: 1150px),
            screen and (min-width: 701px) and (max-width: 900px) {
                max-height: calc(1rem * 1.55 * 3); /* Fallback for non-webkit */
                -webkit-line-clamp: 3;
            }
            @media screen and (min-width: 601px) and (max-width: 700px) {
                max-height: calc(1rem * 1.35 * 5); /* Fallback for non-webkit */
                -webkit-line-clamp: 5;
            }

        }

    }

    .info-box-text {
        padding: 0 2rem 3.75rem;
        position: relative;

        @media all and (min-width: 601px) and (max-width: 700px) {
            padding: 0 1rem 3.12rem;
        }

        @media screen and (max-width: 600px) {
            padding-bottom: 4.12rem;
            padding-left: 1.12rem;
            padding-right: 1.12rem;

            &.extra-bottom-space {
                padding-bottom: 5.12rem;
            }
        }

        &.margin-min {
            padding-bottom: 1rem;
        }

        .title {
            color: $dark-blue;
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 1.55rem;
            margin-top: 0.4rem;

            @media all and (min-width: 601px) and (max-width: 700px) {
                margin-bottom: 0.55rem;
                line-height: 1;
            }

            &.line-limit {
                display: block;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                @media screen and (min-width: 701px) and (max-width: 900px) {
                    max-height: calc(1.25rem * 1.12 * 4); /* Fallback for non-webkit */
                    -webkit-line-clamp: 4;
                }

                @media screen and (min-width: 601px) and (max-width: 700px) {
                    max-height: calc(1.21rem * 4); /* Fallback for non-webkit */
                    -webkit-line-clamp: 4;
                }
            }
        }
        @media all and (min-width: 601px) and (max-width: 700px) {
            .mini-title {
                margin-top: 1.25rem;
            }
        }

        p:not(.mini-title) {
            color: $text-color;
            @media all and (min-width: 601px) and (max-width: 700px) {
                line-height: 1.35;
            }
        }
    }
}

@media screen and (min-width: 601px) and (max-width: 992px) {
    .info-box-margin {
        margin-bottom: 0 !important;
    }
}

.article-link {
    // display: block;
    position: relative;
    height: 100%;
    width: 100%;
}

.read-more {
    position: absolute;
    bottom: 1.55rem;
    left: 2rem;
    z-index: 12;
    font-weight: 400;

    @media screen and (min-width: 601px) and (max-width: 992px) {
        left: calc(290px + 2rem);
        @media screen and (max-width: 700px) {
            left: calc(290px + 1rem);
        }
    }

    @media screen and (max-width: 600px) {
        left: 1.12rem;
    }

    &.btn-horizontal {
        @media screen and (min-width: 993px) {
            left: calc(290px + 2rem);
        }
    }
}

@media screen and (max-width: 600px) {
    .fastfix {
        margin-bottom: 1.55rem;
    }
}

@media all and(max-width: 600px) {
    img.smaller-for-mobile {
        max-height: 175px;
        width: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover';
    }
}

.extra-space {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
}