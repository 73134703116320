.job-description {
    overflow: hidden;
    height: 0;
    width: 100%;

    &.show {
        height: auto;
    }
}

.career-bg {
    //noinspection CssUnknownTarget
    background-image: url(../img/career-bg.jpg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #263a5b !important;
    color: #fff;
}

.show-description {
    width: 250px;
}

.list-style-1 {
    li {
        font-size: 1.1rem;
        font-weight: 500;
        position: relative;
        margin-left: 1.25rem;

        @media screen and (min-width: 1200px) {
            margin-left: 2.55rem;
        }

        &::before {
            content: "►";
            position: absolute;
            color: $light-blue;
            font-size: .9rem;
            //top: -1px;
            left: -1.25rem;
        }
    }
}

.sidebar {
    padding: 1.25rem !important;

    p,
    .fake-p {
        margin-top: 0;
        margin-bottom: 8px;
        line-height: 1.2;
        font-weight: 500;
        font-size: 1.1rem;
    }
    p:last-child {
        margin-bottom: 2rem;
    }

    .title {
        font-size: 1.35rem;
        font-weight: 700;
        margin-top: 1.55rem;
        margin-bottom: 1rem;

        &.extra-margin {
            margin-top: 3rem;

            @media screen and (min-width: 601px) and (max-width: 992px) {
                margin-top: 1.55rem;
            }
        }

        &.highlight {
            color: $light-blue;
        }
    }
}
