.personel {
    //background-color: $grey-light;
    //border-bottom: 4px solid #e1e1e1;
    position: relative;
    z-index: 11;
}

.team-margin-bottom {
    margin-bottom: 24px !important;
}

.boss {
    padding: 1.2rem 0.5rem;
    background-color: $grey-light;
    // border-bottom: 4px solid #e1e1e1;
    hr {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        float: left;
    }
    .name {
        font-size: 1.64rem;
        margin: 0 0 .656rem;
    }
    .sign {
        width: 250px;
        margin-right: 25%;
        transition: 250ms;
    }
    .avatar {
        padding: 1rem 2rem !important;
        max-width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .boss {
        .sign {
            //width: 50%;
            margin-right: 45%;
            margin-bottom: 9%;
        }
    }
}

@media screen and (max-width: 740px) {
    .boss {
        .sign {
            margin-bottom: 4%;
        }
    }
}

@media screen and (max-width: 600px) {
    .boss {
        text-align: center;
        hr {
            float: none;
            display: block;
            margin-left: auto !important;
            margin-right: auto !important;
        }
        hr.stretch {
            margin-right: calc(100% - 2rem) !important;
        }
        .avatar img,
        .sign {
            max-width: 60%;
            display: block;
            margin: 0 auto;
        }
        .list {
            text-align: left;
        }
    }
}

.employee {
    padding: 1.75rem 2.75rem .5rem !important;
    height: 100%;
    margin-bottom: 0 !important;
    hr {
        float: none;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    @media screen and (min-width: 993px) and (max-width: 1160px) {
        padding: 1.75rem 1.5rem .5rem !important;
    }

    .name {
        font-size: 1.25rem;
        font-family: Roboto,sans-serif;
        letter-spacing: .035rem;
        font-weight: 700;
        line-height: 1.12;
        margin: .82rem 0 .656rem;
    }
    .mini-title {
        color: dimgray;
    }
    .person-desc {
        text-align: justify !important;
        margin-bottom: 1rem;

        @media screen and (min-width: 993px) and (max-width: 1160px) {
            text-align: center !important;
        }

    }
    transition: 350ms !important;
}

@media screen and (max-width: 992px) {
    .employee img {
        max-width: 60%;
    }
}

.flex {
    float: left;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        //todo: moze to jest do dopracoawania, naprawa flexa safari 5.1
    }

    &.vcenter {
        align-items: center;
    }
}

.flex-child {
    position: relative;
    border-top: 0;
    border-bottom: 0;
    //flex-wrap: wrap;
    padding: 0 !important;
    background: #ecedef;
    border-left: 14px white solid;
    border-right: 14px white solid;
}

.shadow {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

.border-bottom-grey {
    border-bottom: 4px solid #e1e1e1;
}

.border-bottom-blue {
    border-bottom: 5px solid $light-blue;
    //TODO: make 4px, pixel perfect publocstions.scss gap
}

// .rm-float,
// .rm-float .flex .col,
// .rm-float .flex .col .col {
//     float: none !important;
// }
@media screen and (max-width: 992px) {
    .flex .col:nth-child(-n+2) {
        margin-bottom: 1.55rem;
    }
}

@media screen and (max-width: 600px) {
    .flex .col:nth-child(-n+3) {
        margin-bottom: 1.55rem;
    }
}

// .employee:hover {
//     border-color: #0eb0ee;
//     background-color: $dark-blue;
//     .name {
//         color: #fff;
//     }
//     .person-desc {
//         color: #fff;
//     }
//     transition: 350ms !important;
// }
