$dark-blue: #1d304e;
$dark-blue-menu: #13223c;
$light-blue: #0eb0ee;

$grey: #e1e1e1;
$grey-text: #6e7681;
$grey-highlight: #cfcfcf;

$text-color: #333;
$text-light: #e4e4e4;

$orange: #ff7d01;

// team
$grey-light: #ecedef;

$gold: #ef7a44;
$gold-highlight: #a95725;

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.orange-box {
    background-color: #fb7300;
}

.blue-box {
    background-color: #005ca4;
}

.green-box {
    background-color: #6f9f08;
}

.pink-box {
    background-color: #d63cde;
}

.red-box {
    background-color: #bf3c26;
}

.violet-box {
    background-color: #7730e1;
}
.light-blue-box {
    background-color: #59aae7;
}
.yellow-box {
    background-color: #ffc900;
}
