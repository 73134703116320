@import 'reset';
@import 'materialize';
@import 'var';
@import 'team';
@import 'publications';
@import 'properties';
@import 'career';
@import 'isotope-box';
@import 'submenu';
@import 'infobox';
@import 'modal-custom';
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Main ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

/* * {
    transition: all 250ms;
    // note: to psuje mobilne menu i dziwne zachowanie nav
} */

// ::selection {
//     background: $light-blue;
//     /* WebKit/Blink Browsers */
//     color: $text-coloR
// }
// ::-moz-selection {
//     background: $light-blue;
//     /* WebKit/Blink Browsers */
//     color: $text-color;
// }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

body,
html {
    background: white;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 23px;
    // color: rgba(0,0,0,.87);
    color: $text-color;
}

.block {
    height: 100%;
}

body {
    overflow-y: scroll;
}

main {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@media screen and (min-width: 601px) and (max-width: 700px) {
    // body,
    // html {
    //     font-size: 12px;
    // }
}

@media screen and (max-width: 450px) {
    body,
    html {
        font-size: 14px;
    }
}

header * {
    color: $dark-blue;
    font-size: 2.5rem;
    line-height: 1 !important;
    i {
        color: #fff;
    }
    &.small {
        font-size: 1.85rem;

        @media screen and (min-width: 601px) and (max-width: 815px) {
            font-size: 1.55rem;
        }
        @media screen and (max-width: 600px) {
            font-size: 2rem;
        }
    }
}

@media only screen and (min-width: 993px) and (max-width: 1099px) {
    header * {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 600px) {
    header * {
        font-size: 2rem;
        //margin-bottom: 0;
    }
}

.nd-title {
    position: absolute;
}

main {
    margin-bottom: 0 !important;
}

img,
a {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.035rem;
    font-weight: 700;
    line-height: 1.12;
}

p.quote {
    position: relative;
}

p.quote::before {
    content: "“";
    position: absolute;
    top: 2rem;
    left: -11px;
    font-size: 1.2rem;
}

p.quote::after {
    content: "”";
}

.big {
    font-size: 1.2rem;
}

.row .col {
    padding: 0 .95rem;
}

.row.no-margin {
    margin-bottom: 0;
}

.max-width {
    max-width: 325px;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.italic {
    font-style: italic;
}

.btn {
    border-radius: 25px;
    font-family: arial;
    font-weight: 700;
    font-size: .9rem;
    letter-spacing: .05rem;
    line-height: 38px;
}

.btn-floating {
    border-radius: 50%;
}

.btn-golden {
    background: #ffc003 !important;
    color: #000;
}

.fixed-action-btn {
    z-index: 99;
}

.light { font-weight: initial; }

.collection-style-1 {
    margin: .5rem 0 0 0;
    a.collection-item {
        background-color: $grey;
        border-bottom: 1px rgba(0, 0, 0, .15) solid;
        color: $grey-text;
        vertical-align: middle;
        float: left;
        width: 100%;
        // @media screen and (min-width: 601px) and (max-width: 992px) {
        //     height: 115px;
        // }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            height: 0;
        }
        // float: left;
        .title {
            display: block;
            line-height: 1rem;
            margin-top: 5px;
            margin-bottom: 3px;
            font-size: 1rem;
        }
        @media screen and (min-width: 601px) and (max-width: 700px) {
            .title {
                line-height: 1.2rem;
            }
        }
        .desc {
            color: #0eb0ee;
            font-weight: 400;
            font-size: .8rem;
            line-height: .9rem;
            display: block;
        }
        .adress {
            font-size: .8rem;
            line-height: 1.5;
            font-weight: 500;
        }
    }
    a.collection-item:first-child {
        border-top: 1px rgba(0, 0, 0, .15) solid;
    }
    a.collection-item:last-child {
        border-bottom: 1px rgba(0, 0, 0, .15) solid;
    }
    @media screen and (min-width: 601px) and (max-width: 992px) {
        a.collection-item:nth-child(2) {
            border-top: 1px rgba(0, 0, 0, .15) solid;
        }
        a.collection-item {
            width: 50%;
        }
    }
    .collection-item.active {
        background: $grey-highlight;
        color: $grey-text;
    }
    .collection-item.avatar {
        padding-left: calc(2rem + 100px);
        .circle {
            left: 2rem;
            border-radius: 0;
            // height: calc(100% - 30px);
            height: 53px;
            width: auto;
            max-width: 100%;
            max-height: 100%;
            margin: 5px 0;
        }
    }
}

@media only screen and (min-width: 993px) and (max-width: 1099px) {
    .collection-style-1 .collection-item.avatar .circle {
        left: 1rem;
    }
    .collection-style-1 .collection-item.avatar {
        padding-left: calc(2rem + 100px);
    }
}

@media only screen and (max-width: 992px) {
    // .collection-style-1 {
    //     margin-bottom: 3rem;
    // }
    .collection-style-1 .collection-item.active {
        background-color: #e1e1e1;
        color: #6e7681;
    }
    .collection-style-1 a.collection-item:not(.active):hover {
        background-color: transparent;
    }
}

@media screen and (min-width: 601px) and (max-width: 992px) {
    .collection-style-1 .collection-item {
        display: inline-block !important;
        width: calc(50% - 2px);
    }
}

.collection-style-2 {
    border: none;
    margin: 1rem 0;
    overflow: visible;
    .collection-item {
        border: none;
        float: left;
    }
    .extra-margin-bottom {
        margin-bottom: 1.55rem;
    }
    .collection-item.avatar {
        padding-left: calc(48px + 0.75rem);
        a {
            color: $text-color;
            display: block;
            font-weight: 500;
            font-size: .95rem;
            line-height: 1.35rem;
            cursor: pointer;
            text-transform: uppercase;
        }
        i.circle {
            color: $text-color;
            background: #fff;
        }
        a:hover {
            color: $light-blue;
        }
        .circle {
            left: 0;
        }
        .title {
            color: $light-blue;
            font-weight: 500;
        }
    }
}

.clear {
    clear: both;
}

.padding-2 {
    padding: 2rem !important;
}

.no-side-margin {
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.highlight {
    color: $light-blue;

    &.gold {
        color: $gold-highlight !important;
    }

}

.bold {
    font-weight: 700;
}

.gold {
    background: $gold;
}

.btn-color-light, .btn-color-light:hover, .btn-color-light:focus {
    background: $light-blue;
}

@media only screen and (min-width: 601px) {
    .container {
        width: 95%;
    }
}

// dark
.dark {
    background: $dark-blue;
    color: #fff;
    header * {
        color: white;
    }
    .highlight {
        color: $light-blue;
    }
    .header-desc {
        color: $text-light;
    }
    .collection .collection-item {
        background: transparent;
    }
    .collection-style-2 .collection-item.avatar a {
        color: $text-light;
    }
    .collection-style-2 .collection-item.avatar a:hover {
        color: $light-blue;
    }
    .flex-child {
        background: $dark-blue;
        border-left-color: $dark-blue;
        border-right-color: $dark-blue;
    }
    .btn-empty {
        color: #fff;
        border-color: #fff;
    }
}

//light
.light {
    background: #e1e1e1;
    .flex-child {
        background: #e1e1e1;
        border-left-color: #e1e1e1;
        border-right-color: #e1e1e1;
    }
}

.offset-fix {
    margin-top: -1.5rem;
}

@media screen and (max-width: 600px) {
    .offset-fix {
        margin-top: -2rem;
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Icons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //
i.icon {
    position: relative;
    height: 42px;
    width: 42px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 12px;

    img {
        position: absolute;
        width: 22px;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Slider ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //
.slider {
    // cursor: pointer;
    // background-color: #1d304e;
    height: 100%;
    height: 100vh;
    opacity: 0;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
    animation-delay: 750ms;
    animation-fill-mode: forwards;
}

.slide {
    background-size: cover;
    background-position: center center;
    background-color: #fff;
    height: 100%;
    height: 100vh;

    //@media screen and (max-width: 1300px){
    &.about {
        background-size: 105% auto;
        background-repeat: no-repeat;

        @media screen and (max-width: 992px) {
            background-position-y: 20%;
        }

    }
    //}

    &::after {
        @extend .gradient;
        content: "";
        bottom: 0;
        height: 80%;
        transform: rotate(180deg);
    }

    &.about::after {
        @extend .gradient;
        background: -moz-linear-gradient(top, rgba(29, 48, 78, 0) 0%, rgba(29, 48, 78, 1) 85%) !important;
        background: -webkit-linear-gradient(top, rgba(29, 48, 78, 0) 0%, rgba(29, 48, 78, 1) 85%) !important;
        background: linear-gradient(to bottom, rgba(29, 48, 78, 0) 0%, rgba(29, 48, 78, 1) 85%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0) !important;
        transform: rotate(0);
    }

}

.slides-navigation {
    top: auto !important;
    bottom: 135px !important;
}

.prev {
    margin-left: 30px;
}

.next {
    margin-right: 30px;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Cookie ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

/* cookie ### */

#cookie-container, #cookie-container-slider {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    z-index: 999;

    .align_bottom {
        position: absolute;
        bottom: 1.25rem;
        right: 3.25rem;
    }
    .row {
        margin-bottom: 0 !important;
    }
    @media screen and (max-width: 600px) {
        width: 100% !important;
    }
}

#cookie-container-slider {
    opacity: .75;
}

#cookie-container p, #cookie-container-slider p {
    margin-bottom: 10px;
}

#cookie-container .col, #cookie-container-slider .col {
    padding: 1.25rem 2.25rem;
    margin-top: 1.2rem;
    position: relative;
}

#cookie-policy {
    background: #0eb0ee;
    font-family: Verdana, sans-serif;
    font-size: .8rem !important;

    @media screen and (max-width: 450px) {
        line-height: 1.3;
    }
}

#cookie-policy p {
    color: #fff;
}

#cookie-policy a {
    float: right;
    background-color: #fff;
    border-color: #fff;
    color: #0eb0ee;

    &.normal-link {
        float: initial;
        font-weight: 700;
        text-decoration: underline;
        color: #fff;
        background: none;
    }
}

#cookie-ng {
    background: #000;
    margin-top: 2.5rem;
    display: flex;
    align-items: stretch;
}

#cookie-ng p {
    color: #fff;
    font-weight: bold;
    font-size: 1.15rem;
    padding-bottom: 3rem;
}

#cookie-ng p a {
    color: #0eb0ee;
}

@media screen and (max-width: 600px) {
    #cookie-container .col.button-col {
        margin-top: 0;
        padding-top: 0;
    }

    #cookie-container .col.policy-col {
        padding-bottom: 0;
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Navigation ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //
nav {
    background: $dark-blue;
    ul {
        li {
            a {
                font-size: 1rem;
                font-family: arial;
                font-weight: 700;
                letter-spacing: .075rem;
                line-height: 64px;
                // transition: border-radius .2s ease-out;
                transition: color .2s ease-out;
                // transition: background .2s ease-out;
                height: 64px;
                padding: 0 16px;
                letter-spacing: .05rem;
                // margin-left: 10px;
            }
            a.btn {
                margin-left: 1px;
                margin-right: 1px;
                padding: 0 15px;
                margin-top: 0;
                font-size: 1rem;
                position: relative;
                top: -2px;
                line-height: 36px;
                font-weight: 700;
                -webkit-animation: in 450ms; /* Safari, Chrome and Opera > 12.1 */
                -moz-animation: in 450ms; /* Firefox < 16 */
                -ms-animation: in 450ms; /* Internet Explorer */
                -o-animation: in 450ms; /* Opera < 12.1 */
                animation: in 450ms;
                animation-timing-function: ease-in;
            }

            @keyframes in {
                from {
                    background: transparent;
                    box-shadow: none;
                }
            }

            /* Firefox < 16 */
            @-moz-keyframes in {
                from {
                    background: transparent;
                    box-shadow: none;
                }
            }

            /* Safari, Chrome and Opera > 12.1 */
            @-webkit-keyframes in {
                from {
                    background: transparent;
                    box-shadow: none;
                }
            }

            /* Internet Explorer */
            @-ms-keyframes in {
                from {
                    background: transparent;
                    box-shadow: none;
                }
            }

            /* Opera < 12.1 */
            @-o-keyframes in {
                from {
                    background: transparent;
                    box-shadow: none;
                }
            }

            &.active {
                background: transparent;
            }

            @media screen and (min-width: 993px) {

                &.active + li a {
                    margin-left: 0;
                }
            }

            &:hover {
                a,
                i {
                    color: $light-blue;
                    background: transparent;
                }
                .btn {
                    color: #fff;
                }
            }

            &.active:hover {
                a {
                    background: $light-blue;
                }
            }

            &.highlight {
                background-color: $light-blue;
                height: 64px;
                a {
                    height: 64px;
                    margin: 0;
                    padding: 0 16px;
                }
                span {
                    color: #fff;
                }
            }

            &.highlight:hover {

                a {
                    height: 64px;
                    background-color: #096b92;
                    color: #fff;
                }
            }
        }
        .divider {
            background-color: #3e3e3e;
        }
    }
    .button-collapse {
        float: right;
    }
    .lang {
        a,
        span,
        i {
            color: #717171 !important;
        }
        .active,
        .active i {
            color: #0eb0ee !important;
        }
        @media screen and (max-width: 1140px) {
            display: none;
        }
    }

    .lang.margin-right {
        margin-right: .5rem;
    }
    .extra-padding-right {
        padding-right: 1.25rem;
    }
}

.side-nav .logo {
    height: 56px;
}

@media only screen and (min-width: 993px) and (max-width: 1337px) {
    nav ul li a {
        font-size: .8rem;
        padding: 0 8px;
    }
    nav ul li a.btn {
        font-size: .8rem;
        padding: 0 18px;
    }
    .lang.margin-left {
        margin-left: .8rem;
    }
    .lang.margin-right {
        margin-right: .8rem;
    }
}

@media screen and (min-width: 993px) and (max-width: 1035px) {
    nav a.button-collapse {
        display: block;
    }
    .nav-wrapper .hide-on-med-and-down {
        display: none;
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Mobile navigarion ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //
@media screen and (max-width: 600px) {
    nav ul a {
        line-height: 32px;
    }
}

#mobile-nav {
    background-color: $dark-blue-menu;
    li a {
        color: #fff;
        //line-height: 32px;
    }
    li.active {
        a,
        i {
            color: #fff;
        }
    }
    li.highlight {
        // NOTE: zmienione na optrzeby wrzucenia na serwer
        height: auto;
        a {
            height: auto;
            border-bottom: 3px $dark-blue-menu solid;
            padding: 0 32px;
        }
    }
    img {
        height: 34px;
        margin-top: 11px;
    }
    i {
        color: #fff;
        text-align: center;
    }
    .lang {
        width: 45%;
        float: left;
        display: inline-block;
    }
    .lang a {
        padding-right: 0;
    }
}

@media screen and (min-width: 601px) {
    #mobile-nav {
        img {
            height: 40px;
            margin-top: 12px;
        }
        #mobile-nav-logo {
            margin-bottom: 12px;
        }
    }
}

#mobile-nav-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 64px;
}

#brand-logo {
    height: 48px;
    margin-top: 8px;
    margin-left: 1.75rem;
}

@media only screen and (min-width: 993px) and (max-width: 1099px) {
    #brand-logo {
        margin-left: 1rem;
    }
}

#mobile-brand-logo {
    height: 34px;
    margin-top: 11px;
    margin-left: 20px;
}

@media only screen and (min-width: 601px) {
    #mobile-brand-logo {
        height: 40px;
        margin-top: 12px;
        margin-left: 20px;
    }
}

#submenu-wrapper {
    position: fixed;
    height: 100%;
    height: calc(100vh - 48px);
    background-color: $grey;
    padding: 0;
    padding-bottom: 30px;
    overflow: auto;
    z-index: 99;
    left: 0;
    //todo: pewnie jeszcze@media
    .btn {
        position: absolute;
        bottom: 2.75rem;
        left: 2rem;
        margin-right: .75rem;
    }
    @media screen and (max-width: 992px) {
        header * {
            padding-bottom: 0;
        }
    }
}

.btn-relative {
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    bottom: .25rem !important;
}

.btn-white {
    color: $light-blue;
    background: #fff !important;
}

.btn-empty {
    line-height: 34px;
    background: transparent;
    border: 2px $light-blue solid;
    color: $light-blue;

    &:hover, &:focus {
        background: transparent;
    }

    &.light {
        color: #fff;
        border-color: #fff;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1099px) {
    #submenu-wrapper {
        .btn {
            left: 1rem;
        }
    }
}

@media only screen and (max-width: 992px) {
    #submenu-wrapper {
        position: relative;
        width: 100% !important;
        padding-bottom: 0;
        // min-height: calc( 100vh - 64px);
        height: auto;
        .btn {
            bottom: 1.12rem;
        }
        .row {
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 600px) {
    #submenu-wrapper {
        min-height: calc(100vh - 56px);
        .collection .collection-item.avatar .title {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
        .collection-style-1 a.collection-item .desc {
            font-size: .9rem;
            line-height: 1rem;
            padding-top: 10px;
        }
        .collection-style-1 .collection-item.avatar {
            padding-left: calc(2rem + 115px);
        }
    }
}

.navbar-fixed {
    z-index: 999;
}

.sumbenu-title {
    padding: 0 2rem;
}

@media screen and (max-width: 992px) {
    .sumbenu-title {
        padding: 0 2rem 1rem 2rem;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1099px) {
    .sumbenu-title {
        padding-left: 1rem;
    }
}

#submenu {
    background: white;
}

#submenu ul a {
    color: black;
}

.fixed-action-btn {
    display: none;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Article ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //
article,
.page-header {
    max-width: 1250px;
    margin: 0 auto;
    // padding-bottom: 5rem;
    padding-top: 4rem;
    padding-bottom: 1rem;
    position: relative;

    // padding-top: 0;
    overflow: hidden;
    @media screen and (max-width: 600px) {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
}

.mid-padding {
    padding: 2.55rem 0;
}

@media screen and (max-width: 992px) {
    .mid-padding {
        padding: .75rem 0;
    }

}

.rm-padding-top {
    padding-top: 0;
}

.remove-padding {
    article {
        padding: 0;
    }
}

.rm-margin-bottom {
    margin-bottom: 0 !important;
}

.rm-margin-top {
    margin-top: 0 !important;
}

@media screen and (min-width: 993px) {
    .rm-margin-bottom-mid-and-up {
        margin-bottom: 0 !important;
    }
}

.extra-margin-top {
    margin-top: 2rem;
}

.mini-padding {
    article {
        padding: 1.55rem 1rem 1rem 1rem;
    }
}

@media screen and (max-width: 600px) {
    article.mobile-remove-padding {
        padding-top: 0;
    }
}

// .carousel {
//     height: calc(100vh - 64px) !important;
//     .prev-slide,
//     .next-slide {
//         position: absolute;
//         bottom: 3rem;
//     }
//     .prev-slide {
//         left: 3rem;
//     }
//     .next-slide {
//         right: 3rem;
//     }
//     .carousel-item {
//         background-size: cover;
//         background-position: center;
//     }
//     .carousel-fixed-item {
//         padding: 0 5rem;
//         top: calc(50% - 20px);
//     }
// }

.fullscreen {
    overflow: hidden;
    height: 100%;
    width: 100%;
    height: calc(100vh - 64px) !important;
    //background: url(../img/off-piotrkowska-bg.jpg);
    background-size: cover;
    position: relative;
    user-drag: none;
    .btn {
        padding: 0 1.85rem;
        text-shadow: none;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
    * {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    }
    background-position: center center;
    .fullscreen-extra-top {
        //opacity: 0;
        position: relative;
        top: 8%;
        // text-shadow: black 1px 1px 2px;
        z-index: 5;
        @media screen and (min-width: 601px) {

            &.bottom {
                position: absolute;
                bottom: 16%;
                left: 0;
                right: 0;
                top: auto;
            }
        }

        .mini-title {
            text-shadow: none;
        }
    }
}

.gradient {
    position: absolute;
    width: 100%;
    //background: url(../img/shadow.png);
    //background-repeat: repeat-x;
    //background-size: 100% 100%;
    // opacity: 0.15;
    z-index: 3;
    background: -moz-linear-gradient(top, rgba(134, 134, 134, 0.16) 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(top, rgba(134, 134, 134, 0.16) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(134, 134, 134, 0.16) 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#26868686', endColorstr='#00000000', GradientType=0);
}

.top-gradient {
    @extend .gradient;
    top: 0;
    height: 20%;
}

.bottom-gradient {
    @extend .gradient;
    bottom: 0;
    height: 66.6%;
    transform: rotate(180deg);
}

.light-grey {
    background-color: #ecedef;
}

@-webkit-keyframes bounceInDown {
    0% {
        //opacity: 0;
        -webkit-transform: translateY(-2000px);
    }
    60% {
        //opacity: 1;
        -webkit-transform: translateY(15px);
    }
    80% {
        -webkit-transform: translateY(-3px);
    }
    100% {
        -webkit-transform: translateY(0);
    }
}

@keyframes bounceInDown {
    0% {
        //opacity: 0;
        transform: translateY(-2000px);
    }
    60% {
        //opacity: 1;
        transform: translateY(15px);
    }
    80% {
        transform: translateY(-3px);
    }
    100% {
        transform: translateY(0);
    }
}

@-webkit-keyframes bounceOut {
    0% {
        //opacity: 0;
        -webkit-transform: translateY(0);
    }
    20% {
        //opacity: 1;
        -webkit-transform: translateY(-3px);
    }
    40% {
        -webkit-transform: translateY(15px);
    }
    100% {
        -webkit-transform: translateY(-250px);
    }
}

@keyframes bounceOut {
    0% {
        //opacity: 0;
        transform: translateY(0);
    }
    20% {
        //opacity: 1;
        transform: translateY(-3px);
    }
    40% {
        transform: translateY(15px);
    }
    100% {
        transform: translateY(-250px);
    }
}

.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
}

.bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut;
}

.change-lang {
    // display: none;
    position: fixed;
    top: 84px; //64px + 20px
    right: 20px;
    background-color: #fff;
    padding: 3px 1.55rem;
    border-radius: 25px;
    z-index: 15;
    transition: 200ms;
    -webkit-animation-duration: 1200ms;
    animation-duration: 1200ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    @media screen and (max-width: 992px) {
        position: absolute;
    }

    span {
        color: #989898;
        font-weight: 500;
        font-size: 1.5rem;
        padding: 0 5px;
    }
    a {
        color: #989898;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 2;
    }
    a.active {
        color: $light-blue;
    }
}

@media screen and (max-width: 600px) {
    .carousel {
        height: calc(100vh - 54px) !important;
    }
    .fullscreen {
        height: calc(100vh - 54px);
        .fullscreen-extra-top {
            position: absolute;
            bottom: 8%;
            top: auto;
            width: 100%;
            left: auto;
            right: auto;
        }
        .btn {
            width: 100%;
        }
        .change-lang {
            right: 5%;
        }
    }
}

.header-extra-margin {
    position: relative;
    margin-top: 2.1rem;
    margin-top: calc(2.1rem + 4px) !important;
}

@media screen and (min-width: 601px) and (max-width: 992px) {
    .btn-extra-margin {
        margin-top: 1.25rem;
    }
}

@media screen and (max-width: 600px) {
    .header-extra-margin {
        margin-top: 0 !important;
    }
}

.header-desc {
    // margin-top: calc(2.1rem + 4px);
    margin-bottom: .75rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: $dark-blue;
}

figure {
    margin: 5px 0 0 0;

    &.gap {
        margin-top: 14px;
    }

    a {
        max-width: 100%;

        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
        }
    }

}

hr {
    background-color: $light-blue;
    width: 2rem;
    height: 2px;
    float: left;
    margin-top: 0;
    margin-bottom: 1.75rem;
    border: 0;
    margin-right: 90%;
    margin-right: calc(100% - 2rem);
}

@media screen and (max-width: 600px) {
    hr.mobile-remove-margin-top {
        margin-top: 5px;
    }
}

p,
.p-bold {
    margin-bottom: 8px;

    &.small-margin-bottom {
        margin-bottom: 4px;
    }
}

p {
    font-weight: 400;
}

.p-bold {
    font-size: 1rem;
    font-weight: 700;
}

ul.list {
    width: 100%;
    margin-top: 0.85rem;
    margin-bottom: 1.25rem;
    overflow: hidden;
    float: left;
    .row {
        margin-bottom: 7px !important;
        margin-left: auto;
        margin-right: auto;
        .col.rm-padding {
            padding: 0;
        }
    }
    li {
        position: relative;
        padding: 4px 0 4px 1rem !important;
        line-height: 1.55rem;
        margin-bottom: 0 !important;
    }
    li:before {
        content: "▸";
        color: #0eb0ee;
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
        font-size: 1.2rem;
        margin-left: -4px;
    }
    .border:first-child {
        border-top: 1px rgba(255, 255, 255, 0.12) solid;
    }
    .border {
        display: block;
        position: relative;
        border-bottom: 1px rgba(255, 255, 255, 0.12) solid;
        //border-width: 90%;
        padding: 0 1.25rem 0 1.25rem !important;
        //margin-left: 10px !important;
        //margin-right: 25px !important;
        a {
            height: 100%;
            width: 100%;
            display: block;
            color: #fff;
            transition: 250ms;
            padding: 1rem 0;
        }
        a:hover {
            color: $light-blue;
            transition: 250ms;
        }
    }
    .border:before {
        top: 1rem;
        left: 4px;
    }
}

.list.col {
    padding-left: 0;
    padding-right: 1rem;
}

.list.no-margin {
    margin-top: 0;
}

.list.extra-bottom-margin {
    margin-bottom: 3rem;
}

@media screen and (max-width: 1200px) {
    .list.col.s6 {
        width: 100%;
    }
    .list.rm-emb-phone {
        margin-bottom: 0;
    }
    .list.rm-mt-phone {
        margin-top: 0;
    }
}

@media screen and (min-width: 601px) and (max-width: 992px) {
    .set-list-height li {
        height: 53px;
    }
    .set-list-height li:hover {
        color: #fff;
    }
    .set-list-height li:nth-child(2) {
        border-top: 1px rgba(255, 255, 255, 0.12) solid;
    }
}

@media screen and (max-width: 600px) {
    ul.list {
        .row {
            margin-bottom: 0;
        }
    }
}

.photo-col {
    padding: 0 !important;
    figure {
        margin-bottom: 1.5rem;
    }
}

.light {
    .big-title {
        color: #2a4672;
        text-shadow: none;
    }
}

.big-title {
    color: #fff;
    text-shadow: black 1px 1px 2px, #000 1px 1px 2px, rgba(0, 0, 0, .45) 0 0 7px;
    // color: #2a4672;
    font-weight: 700;
    font-size: 5.55rem;
    margin-top: 0.35rem;
    margin-bottom: 1.68rem;
    line-height: .85;

    &.long {
        font-size: 4rem;
    }
}

@media screen and (max-width: 736px) {
    .big-title {
        font-size: 2.55rem;

        &.long {
            font-size: 2rem;
            line-height: 1;
        }
    }
}

.light {
    .mini-title {
        text-shadow: none;
        span {
            color: #2a4672;
            text-shadow: none;
        }
    }
}

.mini-title {
    color: $light-blue;
    font-size: .9rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .15rem;
    line-height: 1rem;
    margin-top: 2.25rem;
    margin-bottom: 0.75em;
    display: block;
    clear: both;
    span {
        color: #fff;
        // color: #2a4672;
        &.text-shadow {
            text-shadow: #000 1px 1px 1px, #444 0 1px 20px;
        }
    }
    .highlight {
        background: #ffc003;
        padding: 5px 7px;
        line-height: 2.75rem;
        font-size: 1rem;
        text-shadow: #000 1px 1px 1px, #444 0 1px 20px;
        // text-shadow: none;

        @media screen and(max-width: 360px) {
            font-size: .8rem;
        }
    }
    .color-black {
        color: $text-color;
    }
    .thin-medium {
        font-weight: 500;
    }
    a:hover {
        color: $light-blue;
    }
    @media screen and (min-width: 601px) and (max-width: 992px) {
        .fixed-height {
            display: inline-block;
            height: 30px;
        }
    }
}

.mini-title.margin-mini {
    margin-top: .3rem;
}

.mini-title.margin-small {
    margin-top: .65rem;
}

.mini-title.margin-medium {
    margin-top: 1.5rem;
}

.properties {
    margin-top: 1.25rem;
    padding-left: 0 !important;
    font-weight: 500;
}

.prop-name {
    font-size: 1.2rem;
    text-transform: uppercase;

    @media screen and (min-width: 993px) and (max-width: 1175px) {
        font-size: 1rem;
    }
}

.prop-value {
    font-size: 1rem;
    line-height: 0.8rem;
}

@media only screen and (min-width: 1200px) {
    .left-col {
        padding-right: 1.25rem !important;
        max-width: 390px;
    }
    .right-col {
        padding-left: 1.25rem !important;
    }
}

@media only screen and (min-width: 993px) {
    article {
        width: 100%;
    }
}

// @media only screen and (min-width: 1100px) {
//     article {
//         width: 90%;
//     }
// }
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Contact ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //
.footer-bg {
    background-image: url(../img/footer.png);
    background-position: bottom;
    background-repeat-y: no-repeat;
    background-repeat: repeat-x;
    padding-bottom: 300px !important;
}

#contact, .modal {
    margin-bottom: 0;

    //TODO: tmp
    .policy-rodo {
        margin-left: 2.7rem;
        margin-bottom: 2rem;
        margin-right: 12px;
    }

    .left-margin {
        margin-left: 2.7rem;
    }

    .collection.collection-style-2 {
        margin-top: calc(2.1rem + 4px);

        .collection-item.avatar {
            i.circle {
                font-size: 22px;
                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    width: 22px;
                }
            }
        }
    }
    .contact-form {
        margin-top: -1rem;
    }
    form {
        .row .col {
            padding: 0 1rem 0 0;
            margin-left: -1px;
        }
        .row {
            margin-bottom: -5px !important;
        }
        .mini-title {
            margin: 1rem 0;
        }

        .input-field .prefix {
            top: 10px;
            color: #ccc;
        }
        button {
            margin-left: 2.7rem;
            margin-right: 12px;
        }
        input,
        textarea {
            background-color: #f3f3f3;
        }
        [type=checkbox]:checked + label:before {
            border-right-color: #0eb0ee;
            border-bottom-color: #0eb0ee;
        }
        .input-field .prefix ~ .autocomplete-content,
        .input-field .prefix ~ .validate ~ label,
        .input-field .prefix ~ input,
        .input-field .prefix ~ label,
        .input-field .prefix ~ textarea {
            margin-left: 3.5rem;
            width: calc(100% - 4rem - .75rem);
            padding-left: .75rem;
        }
        .input-field.left-space {
            input {
                padding-left: 1.55rem;
            }
        }
        .input-field.special-width {
            display: inline-block;
            width: 50%;
            width: calc(100% - 256px);

            @media screen and (max-width: 800px) {
                width: 90%;
                width: calc(100% - 2rem);
            }
        }
        .input-field.col label {
            left: 0;
        }
        .input-field.left-space label {
            left: 1.55rem;

            &.active {
                left: 0;
            }
        }
        /* label focus color */
        .input-field input[type=text]:focus + label,
        .input-field input[type=number]:focus + label,
        .input-field input[type=email]:focus + label,
        .input-field textarea:focus + label {
            color: $light-blue !important;
        }
        /* label underline focus color */
        .input-field input[type=text]:focus,
        .input-field input[type=number]:focus,
        .input-field input[type=email]:focus,
        .input-field input[type=text]:focus,
        .input-field textarea:focus {
            border-bottom: 1px solid $light-blue !important;
            box-shadow: 0 1px 0 0 $light-blue !important;
        }
        .input-field input[type=text],
        .input-field input[type=number],
        .input-field input[type=email],
        .input-field input[type=text],
        .input-field textarea {
            color: $dark-blue;
        }
        /* icon prefix focus color */
        .input-field .prefix.active {
            color: $light-blue;
        }
        .form-right-button {
            margin-right: 12px;
        }
        @media screen and (max-width: 620px) {
            .row .col {
                padding: 0 1rem 0 0;
                margin-left: 5px;
            }
            .input-field .prefix ~ .autocomplete-content,
            .input-field .prefix ~ .validate ~ label,
            .input-field .prefix ~ input,
            .input-field .prefix ~ label,
            .input-field .prefix ~ textarea {
                margin-left: 3.6rem;
            }
            button, .form-right-button {
                margin-left: 3rem;
            }
            .form-right-button {
                float: left !important;
            }
        }
    }
    @media screen and (min-width: 1350px) {
        .contact-header {
            width: 33.33333%;
        }
        .contact-list {
            width: 66.66667%;
        }
        .contact-form {
            margin-left: 33.33333%;
            width: 66.66667%;
        }
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ table ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

.table-name {
    margin-top: 3rem;
    font-size: 1.4rem;
    margin-bottom: .5rem;

    .table-caption {
        font-size: 1.2rem;
        font-weight: 400;
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ test ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

/* .row {
    border: 2px green dotted;
}

.col {
    border: 2px lightblue solid;
}

article {
    border: 2px black solid;
} */

@media screen and (max-width: 600px) {
    .mobile-extra-margin {
        margin-top: 1.25rem !important;
    }
    .mobile-extra-margin-header {
        margin-top: 2rem !important;
    }
    .mobile-extra-margin-medium {
        margin-top: 2.25rem !important;
    }
}

/*

.row-flex, .row-flex > div{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1 1 auto;
}

.row-flex-wrap {
    -webkit-flex-flow: row wrap;
    align-content: flex-start;
    flex: 0;
}

.row-flex > .col, .container-flex > .col {
     margin:-.2px; /* hack adjust for wrapping */

/*
}

.container-flex > .col div,.row-flex > .col div {
    width:100%;
}


.flex-col {
    display: flex;
    display: -webkit-flex;
    flex: 1 100%;
    flex-flow: column nowrap;
}

.flex-grow {
    display: flex;
    -webkit-flex: 2;
    flex: 2;
}

*/

.clearfix {
    clear: both;

    &.on-med-and-down {
        @media screen and (min-width: 992px) {
            clear: initial;
        }
    }
}

@media screen and (max-width: 450px) {
    .hide-on-very-small {
        display: none;
    }
}

.toast-error {
    background-color: rgba(240, 10, 10, .95);
}

.toast-success {
    background-color: rgba(76, 175, 80, .95);
}

.lazy {
    text-indent: -99999px;
    white-space: nowrap;
    overflow: hidden;
}

// SEO update

.orange-box-header {
    font-size: 2.28rem;
    margin: 1.14rem 0 .912rem;
}

.fake-header {
    letter-spacing: .035rem;
    font-weight: 700;
    margin: 2.1rem 0 1.68rem;
}

.normal-text {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    font-weight: 400;
}

.subtitle {
    font-size: 1.64rem;
    margin: .82rem 0 .656rem;
}

.subtitle-publications {
    font-weight: normal;
    font-size: 1.25rem;

    @media screen and (min-width: 993px) {
        max-width: 235px;
    }
}

.page-header {
    padding-bottom: 0;
    margin-bottom: 0;
    overflow: visible;

    h1,
    .row {
        margin-bottom: 0;
    }

    h1 {
        font-size: 2rem;
        font-weight: 700;
    }
}